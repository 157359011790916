// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-bitsmo-exchange-js": () => import("../src/pages/portfolio/bitsmo-exchange.js" /* webpackChunkName: "component---src-pages-portfolio-bitsmo-exchange-js" */),
  "component---src-pages-portfolio-personal-site-js": () => import("../src/pages/portfolio/personal-site.js" /* webpackChunkName: "component---src-pages-portfolio-personal-site-js" */),
  "component---src-pages-portfolio-skyrouter-js": () => import("../src/pages/portfolio/skyrouter.js" /* webpackChunkName: "component---src-pages-portfolio-skyrouter-js" */)
}

